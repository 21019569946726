/* Starting Nav bg color */
.nav-bg {
    background-color: #4FBBB4; 
  }

  /* Search button */
.search-style {
      border-radius: 53px 53px 53px 53px;
  }
.search-btn {
      border-radius: 53px 53px 53px 53px;
      cursor:pointer;
  }

.download-btn {
    border-radius: 14px;
    background-color: #4FBBB4;
}

.custom-tab {
  margin-right: 20px; /* Adjust the spacing as needed */
}

.description {
  font-size: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.word-description {
  word-wrap: break-word;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.line {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  margin-left: 80px;
  margin-right: 80px;
}

.form{
  width: 100%;
  display: flex;
  justify-content: center;
}
.form .articleinput{
  background-color: #FDD77F;
  border-color: #FDD77F;
  border-radius: 21px;
  width: 500px;
  height: 40px;
}
.form .articlebutton{
  background-color: #4FBBB4;
  border-color: #4FBBB4;
  border-radius: 21px;
  height: 40px;
  width: 200px;
}
.form .space{
  width: 10px;
}


@media only screen and (max-width: 600px){
  .form{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form .articleinput{
    width: 300px;
  }
  .form .articlebutton{
    margin-top: 10px;
    width: 200px;
  }
  .form .space{
    width: 0;
  }
}

#home
{
  background-image: url(../images/overjoyed_webp.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
  z-index: -1;
}

@media only screen and (max-width: 600px){

  #home{
    background-position: 20% 0%;
    background-size: 400%;
  
  }
}








